/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;700&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 62.5%; }
  @media (min-width: 1200px) {
    html {
      font-size: 50%; } }
  @media (min-width: 1440px) {
    html {
      font-size: 62.5%; } }

body {
  background: #e9e9e9;
  overflow-x: hidden;
  font-family: "Teko", sans-serif; }

a {
  transition: 0.3s;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

p {
  font-family: "Aller";
  font-size: 1.4rem;
  color: #353535;
  margin-bottom: 2rem; }
  @media (min-width: 576px) {
    p {
      font-size: 1.6rem; } }

button {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  transition: 0.3s; }
  button:focus {
    outline: none; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul li {
    text-decoration: none; }

.owl-carousel .animated {
  animation-duration: 2s; }

.lb-close {
  position: absolute;
  right: 1rem;
  top: -4rem; }

@font-face {
  font-family: "Aller";
  src: url("../fonts/AllerRg.eot");
  src: url("../fonts/AllerRg.eot?#iefix") format("embedded-opentype"), url("../fonts/AllerRg.otf") format("opentype"), url("../fonts/AllerRg.svg") format("svg"), url("../fonts/AllerRg.ttf") format("truetype"), url("../fonts/AllerRg.woff") format("woff"), url("../fonts/AllerRg.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Aller-Bold";
  src: url("../fonts/AllerBd.eot");
  src: url("../fonts/AllerBd.eot?#iefix") format("embedded-opentype"), url("../fonts/AllerBd.otf") format("opentype"), url("../fonts/AllerBd.svg") format("svg"), url("../fonts/AllerBd.ttf") format("truetype"), url("../fonts/AllerBd.woff") format("woff"), url("../fonts/AllerBd.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; } }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

@media (min-width: 1200px) {
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; } }

@media (min-width: 992px) {
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; } }

@media (min-width: 768px) {
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; } }

@media (min-width: 576px) {
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; } }

.pl-3,
.px-3 {
  padding-left: 1.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; } }

.pl-4,
.px-4 {
  padding-left: 2rem !important; }

.pr-4,
.px-4 {
  padding-right: 2rem !important; }

.pt-4,
.py-4 {
  padding-top: 2rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2rem !important; }

.mt-4,
.my-4 {
  margin-top: 2rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2rem !important; }

@media (min-width: 1200px) {
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem !important; } }

@media (min-width: 992px) {
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem !important; } }

@media (min-width: 768px) {
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem !important; } }

@media (min-width: 576px) {
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem !important; } }

.pl-5,
.px-5 {
  padding-left: 2.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.5rem !important; } }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

@media (min-width: 1200px) {
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; } }

@media (min-width: 992px) {
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; } }

@media (min-width: 768px) {
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; } }

@media (min-width: 576px) {
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; } }

.pl-7,
.px-7 {
  padding-left: 3.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 3.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 3.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 3.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.5rem !important; } }

.pl-8,
.px-8 {
  padding-left: 4rem !important; }

.pr-8,
.px-8 {
  padding-right: 4rem !important; }

.pt-8,
.py-8 {
  padding-top: 4rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 4rem !important; }

.ml-8,
.mx-8 {
  margin-left: 4rem !important; }

.mr-8,
.mx-8 {
  margin-right: 4rem !important; }

.mt-8,
.my-8 {
  margin-top: 4rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 4rem !important; }

@media (min-width: 1200px) {
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem !important; } }

@media (min-width: 992px) {
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem !important; } }

@media (min-width: 768px) {
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem !important; } }

@media (min-width: 576px) {
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem !important; } }

.pl-9,
.px-9 {
  padding-left: 4.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 4.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 4.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 4.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 4.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 4.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 4.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 4.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.5rem !important; } }

.pl-10,
.px-10 {
  padding-left: 5rem !important; }

.pr-10,
.px-10 {
  padding-right: 5rem !important; }

.pt-10,
.py-10 {
  padding-top: 5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 5rem !important; }

.mt-10,
.my-10 {
  margin-top: 5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important; } }

@media (min-width: 768px) {
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important; } }

.pl-11,
.px-11 {
  padding-left: 5.5rem !important; }

.pr-11,
.px-11 {
  padding-right: 5.5rem !important; }

.pt-11,
.py-11 {
  padding-top: 5.5rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 5.5rem !important; }

.ml-11,
.mx-11 {
  margin-left: 5.5rem !important; }

.mr-11,
.mx-11 {
  margin-right: 5.5rem !important; }

.mt-11,
.my-11 {
  margin-top: 5.5rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 5.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem !important; } }

.pl-12,
.px-12 {
  padding-left: 6rem !important; }

.pr-12,
.px-12 {
  padding-right: 6rem !important; }

.pt-12,
.py-12 {
  padding-top: 6rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 6rem !important; }

.ml-12,
.mx-12 {
  margin-left: 6rem !important; }

.mr-12,
.mx-12 {
  margin-right: 6rem !important; }

.mt-12,
.my-12 {
  margin-top: 6rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 6rem !important; }

@media (min-width: 1200px) {
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6rem !important; } }

@media (min-width: 992px) {
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6rem !important; } }

@media (min-width: 768px) {
  .pl-md-12,
  .px-md-12 {
    padding-left: 6rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6rem !important; } }

@media (min-width: 576px) {
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6rem !important; } }

.pl-13,
.px-13 {
  padding-left: 6.5rem !important; }

.pr-13,
.px-13 {
  padding-right: 6.5rem !important; }

.pt-13,
.py-13 {
  padding-top: 6.5rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 6.5rem !important; }

.ml-13,
.mx-13 {
  margin-left: 6.5rem !important; }

.mr-13,
.mx-13 {
  margin-right: 6.5rem !important; }

.mt-13,
.my-13 {
  margin-top: 6.5rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 6.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6.5rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6.5rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6.5rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6.5rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6.5rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6.5rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6.5rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6.5rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6.5rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6.5rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6.5rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6.5rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6.5rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6.5rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-13,
  .px-md-13 {
    padding-left: 6.5rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6.5rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6.5rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6.5rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6.5rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6.5rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6.5rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6.5rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6.5rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6.5rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6.5rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6.5rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6.5rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6.5rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6.5rem !important; } }

.pl-14,
.px-14 {
  padding-left: 7rem !important; }

.pr-14,
.px-14 {
  padding-right: 7rem !important; }

.pt-14,
.py-14 {
  padding-top: 7rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 7rem !important; }

.ml-14,
.mx-14 {
  margin-left: 7rem !important; }

.mr-14,
.mx-14 {
  margin-right: 7rem !important; }

.mt-14,
.my-14 {
  margin-top: 7rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 7rem !important; }

@media (min-width: 1200px) {
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 7rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 7rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 7rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 7rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 7rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 7rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 7rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 7rem !important; } }

@media (min-width: 992px) {
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 7rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 7rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 7rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 7rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 7rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 7rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 7rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 7rem !important; } }

@media (min-width: 768px) {
  .pl-md-14,
  .px-md-14 {
    padding-left: 7rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 7rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 7rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 7rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 7rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 7rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 7rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 7rem !important; } }

@media (min-width: 576px) {
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 7rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 7rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 7rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 7rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 7rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 7rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 7rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 7rem !important; } }

.pl-15,
.px-15 {
  padding-left: 7.5rem !important; }

.pr-15,
.px-15 {
  padding-right: 7.5rem !important; }

.pt-15,
.py-15 {
  padding-top: 7.5rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 7.5rem !important; }

.ml-15,
.mx-15 {
  margin-left: 7.5rem !important; }

.mr-15,
.mx-15 {
  margin-right: 7.5rem !important; }

.mt-15,
.my-15 {
  margin-top: 7.5rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 7.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7.5rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7.5rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7.5rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7.5rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7.5rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7.5rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7.5rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7.5rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7.5rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7.5rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7.5rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7.5rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7.5rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7.5rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-15,
  .px-md-15 {
    padding-left: 7.5rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7.5rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7.5rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7.5rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7.5rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7.5rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7.5rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7.5rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7.5rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7.5rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7.5rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7.5rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7.5rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7.5rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7.5rem !important; } }

.pl-16,
.px-16 {
  padding-left: 8rem !important; }

.pr-16,
.px-16 {
  padding-right: 8rem !important; }

.pt-16,
.py-16 {
  padding-top: 8rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 8rem !important; }

.ml-16,
.mx-16 {
  margin-left: 8rem !important; }

.mr-16,
.mx-16 {
  margin-right: 8rem !important; }

.mt-16,
.my-16 {
  margin-top: 8rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 8rem !important; }

@media (min-width: 1200px) {
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 8rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 8rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 8rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 8rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 8rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 8rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 8rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 8rem !important; } }

@media (min-width: 992px) {
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 8rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 8rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 8rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 8rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 8rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 8rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 8rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 8rem !important; } }

@media (min-width: 768px) {
  .pl-md-16,
  .px-md-16 {
    padding-left: 8rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 8rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 8rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 8rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 8rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 8rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 8rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 8rem !important; } }

@media (min-width: 576px) {
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 8rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 8rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 8rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 8rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 8rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 8rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 8rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 8rem !important; } }

.pl-17,
.px-17 {
  padding-left: 8.5rem !important; }

.pr-17,
.px-17 {
  padding-right: 8.5rem !important; }

.pt-17,
.py-17 {
  padding-top: 8.5rem !important; }

.pb-17,
.py-17 {
  padding-bottom: 8.5rem !important; }

.ml-17,
.mx-17 {
  margin-left: 8.5rem !important; }

.mr-17,
.mx-17 {
  margin-right: 8.5rem !important; }

.mt-17,
.my-17 {
  margin-top: 8.5rem !important; }

.mb-17,
.my-17 {
  margin-bottom: 8.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 8.5rem !important; }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 8.5rem !important; }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 8.5rem !important; }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 8.5rem !important; }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 8.5rem !important; }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 8.5rem !important; }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 8.5rem !important; }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 8.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 8.5rem !important; }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 8.5rem !important; }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 8.5rem !important; }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 8.5rem !important; }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 8.5rem !important; }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 8.5rem !important; }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 8.5rem !important; }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 8.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-17,
  .px-md-17 {
    padding-left: 8.5rem !important; }
  .pr-md-17,
  .px-md-17 {
    padding-right: 8.5rem !important; }
  .pt-md-17,
  .py-md-17 {
    padding-top: 8.5rem !important; }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 8.5rem !important; }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 8.5rem !important; }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 8.5rem !important; }
  .mt-md-17,
  .my-md-17 {
    margin-top: 8.5rem !important; }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 8.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 8.5rem !important; }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 8.5rem !important; }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 8.5rem !important; }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 8.5rem !important; }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 8.5rem !important; }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 8.5rem !important; }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 8.5rem !important; }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 8.5rem !important; } }

.pl-18,
.px-18 {
  padding-left: 9rem !important; }

.pr-18,
.px-18 {
  padding-right: 9rem !important; }

.pt-18,
.py-18 {
  padding-top: 9rem !important; }

.pb-18,
.py-18 {
  padding-bottom: 9rem !important; }

.ml-18,
.mx-18 {
  margin-left: 9rem !important; }

.mr-18,
.mx-18 {
  margin-right: 9rem !important; }

.mt-18,
.my-18 {
  margin-top: 9rem !important; }

.mb-18,
.my-18 {
  margin-bottom: 9rem !important; }

@media (min-width: 1200px) {
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 9rem !important; }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 9rem !important; }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 9rem !important; }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 9rem !important; }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 9rem !important; }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 9rem !important; }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 9rem !important; }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 9rem !important; } }

@media (min-width: 992px) {
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 9rem !important; }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 9rem !important; }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 9rem !important; }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 9rem !important; }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 9rem !important; }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 9rem !important; }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 9rem !important; }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 9rem !important; } }

@media (min-width: 768px) {
  .pl-md-18,
  .px-md-18 {
    padding-left: 9rem !important; }
  .pr-md-18,
  .px-md-18 {
    padding-right: 9rem !important; }
  .pt-md-18,
  .py-md-18 {
    padding-top: 9rem !important; }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 9rem !important; }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 9rem !important; }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 9rem !important; }
  .mt-md-18,
  .my-md-18 {
    margin-top: 9rem !important; }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 9rem !important; } }

@media (min-width: 576px) {
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 9rem !important; }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 9rem !important; }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 9rem !important; }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 9rem !important; }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 9rem !important; }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 9rem !important; }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 9rem !important; }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 9rem !important; } }

.pl-19,
.px-19 {
  padding-left: 9.5rem !important; }

.pr-19,
.px-19 {
  padding-right: 9.5rem !important; }

.pt-19,
.py-19 {
  padding-top: 9.5rem !important; }

.pb-19,
.py-19 {
  padding-bottom: 9.5rem !important; }

.ml-19,
.mx-19 {
  margin-left: 9.5rem !important; }

.mr-19,
.mx-19 {
  margin-right: 9.5rem !important; }

.mt-19,
.my-19 {
  margin-top: 9.5rem !important; }

.mb-19,
.my-19 {
  margin-bottom: 9.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 9.5rem !important; }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 9.5rem !important; }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 9.5rem !important; }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 9.5rem !important; }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 9.5rem !important; }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 9.5rem !important; }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 9.5rem !important; }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 9.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 9.5rem !important; }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 9.5rem !important; }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 9.5rem !important; }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 9.5rem !important; }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 9.5rem !important; }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 9.5rem !important; }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 9.5rem !important; }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 9.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-19,
  .px-md-19 {
    padding-left: 9.5rem !important; }
  .pr-md-19,
  .px-md-19 {
    padding-right: 9.5rem !important; }
  .pt-md-19,
  .py-md-19 {
    padding-top: 9.5rem !important; }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 9.5rem !important; }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 9.5rem !important; }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 9.5rem !important; }
  .mt-md-19,
  .my-md-19 {
    margin-top: 9.5rem !important; }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 9.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 9.5rem !important; }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 9.5rem !important; }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 9.5rem !important; }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 9.5rem !important; }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 9.5rem !important; }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 9.5rem !important; }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 9.5rem !important; }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 9.5rem !important; } }

.pl-20,
.px-20 {
  padding-left: 10rem !important; }

.pr-20,
.px-20 {
  padding-right: 10rem !important; }

.pt-20,
.py-20 {
  padding-top: 10rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 10rem !important; }

.ml-20,
.mx-20 {
  margin-left: 10rem !important; }

.mr-20,
.mx-20 {
  margin-right: 10rem !important; }

.mt-20,
.my-20 {
  margin-top: 10rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 10rem !important; }

@media (min-width: 1200px) {
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 10rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 10rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 10rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 10rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 10rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 10rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 10rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 10rem !important; } }

@media (min-width: 992px) {
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 10rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 10rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 10rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 10rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 10rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 10rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 10rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 10rem !important; } }

@media (min-width: 768px) {
  .pl-md-20,
  .px-md-20 {
    padding-left: 10rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 10rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 10rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 10rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 10rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 10rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 10rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 10rem !important; } }

@media (min-width: 576px) {
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 10rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 10rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 10rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 10rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 10rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 10rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 10rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 10rem !important; } }

.pl-21,
.px-21 {
  padding-left: 10.5rem !important; }

.pr-21,
.px-21 {
  padding-right: 10.5rem !important; }

.pt-21,
.py-21 {
  padding-top: 10.5rem !important; }

.pb-21,
.py-21 {
  padding-bottom: 10.5rem !important; }

.ml-21,
.mx-21 {
  margin-left: 10.5rem !important; }

.mr-21,
.mx-21 {
  margin-right: 10.5rem !important; }

.mt-21,
.my-21 {
  margin-top: 10.5rem !important; }

.mb-21,
.my-21 {
  margin-bottom: 10.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 10.5rem !important; }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 10.5rem !important; }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 10.5rem !important; }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 10.5rem !important; }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 10.5rem !important; }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 10.5rem !important; }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 10.5rem !important; }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 10.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 10.5rem !important; }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 10.5rem !important; }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 10.5rem !important; }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 10.5rem !important; }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 10.5rem !important; }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 10.5rem !important; }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 10.5rem !important; }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 10.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-21,
  .px-md-21 {
    padding-left: 10.5rem !important; }
  .pr-md-21,
  .px-md-21 {
    padding-right: 10.5rem !important; }
  .pt-md-21,
  .py-md-21 {
    padding-top: 10.5rem !important; }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 10.5rem !important; }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 10.5rem !important; }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 10.5rem !important; }
  .mt-md-21,
  .my-md-21 {
    margin-top: 10.5rem !important; }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 10.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 10.5rem !important; }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 10.5rem !important; }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 10.5rem !important; }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 10.5rem !important; }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 10.5rem !important; }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 10.5rem !important; }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 10.5rem !important; }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 10.5rem !important; } }

.pl-22,
.px-22 {
  padding-left: 11rem !important; }

.pr-22,
.px-22 {
  padding-right: 11rem !important; }

.pt-22,
.py-22 {
  padding-top: 11rem !important; }

.pb-22,
.py-22 {
  padding-bottom: 11rem !important; }

.ml-22,
.mx-22 {
  margin-left: 11rem !important; }

.mr-22,
.mx-22 {
  margin-right: 11rem !important; }

.mt-22,
.my-22 {
  margin-top: 11rem !important; }

.mb-22,
.my-22 {
  margin-bottom: 11rem !important; }

@media (min-width: 1200px) {
  .pl-xl-22,
  .px-xl-22 {
    padding-left: 11rem !important; }
  .pr-xl-22,
  .px-xl-22 {
    padding-right: 11rem !important; }
  .pt-xl-22,
  .py-xl-22 {
    padding-top: 11rem !important; }
  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 11rem !important; }
  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 11rem !important; }
  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 11rem !important; }
  .mt-xl-22,
  .my-xl-22 {
    margin-top: 11rem !important; }
  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 11rem !important; } }

@media (min-width: 992px) {
  .pl-lg-22,
  .px-lg-22 {
    padding-left: 11rem !important; }
  .pr-lg-22,
  .px-lg-22 {
    padding-right: 11rem !important; }
  .pt-lg-22,
  .py-lg-22 {
    padding-top: 11rem !important; }
  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 11rem !important; }
  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 11rem !important; }
  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 11rem !important; }
  .mt-lg-22,
  .my-lg-22 {
    margin-top: 11rem !important; }
  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 11rem !important; } }

@media (min-width: 768px) {
  .pl-md-22,
  .px-md-22 {
    padding-left: 11rem !important; }
  .pr-md-22,
  .px-md-22 {
    padding-right: 11rem !important; }
  .pt-md-22,
  .py-md-22 {
    padding-top: 11rem !important; }
  .pb-md-22,
  .py-md-22 {
    padding-bottom: 11rem !important; }
  .ml-md-22,
  .mx-md-22 {
    margin-left: 11rem !important; }
  .mr-md-22,
  .mx-md-22 {
    margin-right: 11rem !important; }
  .mt-md-22,
  .my-md-22 {
    margin-top: 11rem !important; }
  .mb-md-22,
  .my-md-22 {
    margin-bottom: 11rem !important; } }

@media (min-width: 576px) {
  .pl-sm-22,
  .px-sm-22 {
    padding-left: 11rem !important; }
  .pr-sm-22,
  .px-sm-22 {
    padding-right: 11rem !important; }
  .pt-sm-22,
  .py-sm-22 {
    padding-top: 11rem !important; }
  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 11rem !important; }
  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 11rem !important; }
  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 11rem !important; }
  .mt-sm-22,
  .my-sm-22 {
    margin-top: 11rem !important; }
  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 11rem !important; } }

.pl-23,
.px-23 {
  padding-left: 11.5rem !important; }

.pr-23,
.px-23 {
  padding-right: 11.5rem !important; }

.pt-23,
.py-23 {
  padding-top: 11.5rem !important; }

.pb-23,
.py-23 {
  padding-bottom: 11.5rem !important; }

.ml-23,
.mx-23 {
  margin-left: 11.5rem !important; }

.mr-23,
.mx-23 {
  margin-right: 11.5rem !important; }

.mt-23,
.my-23 {
  margin-top: 11.5rem !important; }

.mb-23,
.my-23 {
  margin-bottom: 11.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-23,
  .px-xl-23 {
    padding-left: 11.5rem !important; }
  .pr-xl-23,
  .px-xl-23 {
    padding-right: 11.5rem !important; }
  .pt-xl-23,
  .py-xl-23 {
    padding-top: 11.5rem !important; }
  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 11.5rem !important; }
  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 11.5rem !important; }
  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 11.5rem !important; }
  .mt-xl-23,
  .my-xl-23 {
    margin-top: 11.5rem !important; }
  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 11.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-23,
  .px-lg-23 {
    padding-left: 11.5rem !important; }
  .pr-lg-23,
  .px-lg-23 {
    padding-right: 11.5rem !important; }
  .pt-lg-23,
  .py-lg-23 {
    padding-top: 11.5rem !important; }
  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 11.5rem !important; }
  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 11.5rem !important; }
  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 11.5rem !important; }
  .mt-lg-23,
  .my-lg-23 {
    margin-top: 11.5rem !important; }
  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 11.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-23,
  .px-md-23 {
    padding-left: 11.5rem !important; }
  .pr-md-23,
  .px-md-23 {
    padding-right: 11.5rem !important; }
  .pt-md-23,
  .py-md-23 {
    padding-top: 11.5rem !important; }
  .pb-md-23,
  .py-md-23 {
    padding-bottom: 11.5rem !important; }
  .ml-md-23,
  .mx-md-23 {
    margin-left: 11.5rem !important; }
  .mr-md-23,
  .mx-md-23 {
    margin-right: 11.5rem !important; }
  .mt-md-23,
  .my-md-23 {
    margin-top: 11.5rem !important; }
  .mb-md-23,
  .my-md-23 {
    margin-bottom: 11.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-23,
  .px-sm-23 {
    padding-left: 11.5rem !important; }
  .pr-sm-23,
  .px-sm-23 {
    padding-right: 11.5rem !important; }
  .pt-sm-23,
  .py-sm-23 {
    padding-top: 11.5rem !important; }
  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 11.5rem !important; }
  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 11.5rem !important; }
  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 11.5rem !important; }
  .mt-sm-23,
  .my-sm-23 {
    margin-top: 11.5rem !important; }
  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 11.5rem !important; } }

.pl-24,
.px-24 {
  padding-left: 12rem !important; }

.pr-24,
.px-24 {
  padding-right: 12rem !important; }

.pt-24,
.py-24 {
  padding-top: 12rem !important; }

.pb-24,
.py-24 {
  padding-bottom: 12rem !important; }

.ml-24,
.mx-24 {
  margin-left: 12rem !important; }

.mr-24,
.mx-24 {
  margin-right: 12rem !important; }

.mt-24,
.my-24 {
  margin-top: 12rem !important; }

.mb-24,
.my-24 {
  margin-bottom: 12rem !important; }

@media (min-width: 1200px) {
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 12rem !important; }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 12rem !important; }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 12rem !important; }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 12rem !important; }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 12rem !important; }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 12rem !important; }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 12rem !important; }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 12rem !important; } }

@media (min-width: 992px) {
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 12rem !important; }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 12rem !important; }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 12rem !important; }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 12rem !important; }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 12rem !important; }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 12rem !important; }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 12rem !important; }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 12rem !important; } }

@media (min-width: 768px) {
  .pl-md-24,
  .px-md-24 {
    padding-left: 12rem !important; }
  .pr-md-24,
  .px-md-24 {
    padding-right: 12rem !important; }
  .pt-md-24,
  .py-md-24 {
    padding-top: 12rem !important; }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 12rem !important; }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 12rem !important; }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 12rem !important; }
  .mt-md-24,
  .my-md-24 {
    margin-top: 12rem !important; }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 12rem !important; } }

@media (min-width: 576px) {
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 12rem !important; }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 12rem !important; }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 12rem !important; }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 12rem !important; }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 12rem !important; }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 12rem !important; }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 12rem !important; }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 12rem !important; } }

.pl-25,
.px-25 {
  padding-left: 12.5rem !important; }

.pr-25,
.px-25 {
  padding-right: 12.5rem !important; }

.pt-25,
.py-25 {
  padding-top: 12.5rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 12.5rem !important; }

.ml-25,
.mx-25 {
  margin-left: 12.5rem !important; }

.mr-25,
.mx-25 {
  margin-right: 12.5rem !important; }

.mt-25,
.my-25 {
  margin-top: 12.5rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 12.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 12.5rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 12.5rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 12.5rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 12.5rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 12.5rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 12.5rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 12.5rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 12.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 12.5rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 12.5rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 12.5rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 12.5rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 12.5rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 12.5rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 12.5rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 12.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-25,
  .px-md-25 {
    padding-left: 12.5rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 12.5rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 12.5rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 12.5rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 12.5rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 12.5rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 12.5rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 12.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 12.5rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 12.5rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 12.5rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 12.5rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 12.5rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 12.5rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 12.5rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 12.5rem !important; } }

.pl-26,
.px-26 {
  padding-left: 13rem !important; }

.pr-26,
.px-26 {
  padding-right: 13rem !important; }

.pt-26,
.py-26 {
  padding-top: 13rem !important; }

.pb-26,
.py-26 {
  padding-bottom: 13rem !important; }

.ml-26,
.mx-26 {
  margin-left: 13rem !important; }

.mr-26,
.mx-26 {
  margin-right: 13rem !important; }

.mt-26,
.my-26 {
  margin-top: 13rem !important; }

.mb-26,
.my-26 {
  margin-bottom: 13rem !important; }

@media (min-width: 1200px) {
  .pl-xl-26,
  .px-xl-26 {
    padding-left: 13rem !important; }
  .pr-xl-26,
  .px-xl-26 {
    padding-right: 13rem !important; }
  .pt-xl-26,
  .py-xl-26 {
    padding-top: 13rem !important; }
  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 13rem !important; }
  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 13rem !important; }
  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 13rem !important; }
  .mt-xl-26,
  .my-xl-26 {
    margin-top: 13rem !important; }
  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 13rem !important; } }

@media (min-width: 992px) {
  .pl-lg-26,
  .px-lg-26 {
    padding-left: 13rem !important; }
  .pr-lg-26,
  .px-lg-26 {
    padding-right: 13rem !important; }
  .pt-lg-26,
  .py-lg-26 {
    padding-top: 13rem !important; }
  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 13rem !important; }
  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 13rem !important; }
  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 13rem !important; }
  .mt-lg-26,
  .my-lg-26 {
    margin-top: 13rem !important; }
  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 13rem !important; } }

@media (min-width: 768px) {
  .pl-md-26,
  .px-md-26 {
    padding-left: 13rem !important; }
  .pr-md-26,
  .px-md-26 {
    padding-right: 13rem !important; }
  .pt-md-26,
  .py-md-26 {
    padding-top: 13rem !important; }
  .pb-md-26,
  .py-md-26 {
    padding-bottom: 13rem !important; }
  .ml-md-26,
  .mx-md-26 {
    margin-left: 13rem !important; }
  .mr-md-26,
  .mx-md-26 {
    margin-right: 13rem !important; }
  .mt-md-26,
  .my-md-26 {
    margin-top: 13rem !important; }
  .mb-md-26,
  .my-md-26 {
    margin-bottom: 13rem !important; } }

@media (min-width: 576px) {
  .pl-sm-26,
  .px-sm-26 {
    padding-left: 13rem !important; }
  .pr-sm-26,
  .px-sm-26 {
    padding-right: 13rem !important; }
  .pt-sm-26,
  .py-sm-26 {
    padding-top: 13rem !important; }
  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 13rem !important; }
  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 13rem !important; }
  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 13rem !important; }
  .mt-sm-26,
  .my-sm-26 {
    margin-top: 13rem !important; }
  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 13rem !important; } }

.pl-27,
.px-27 {
  padding-left: 13.5rem !important; }

.pr-27,
.px-27 {
  padding-right: 13.5rem !important; }

.pt-27,
.py-27 {
  padding-top: 13.5rem !important; }

.pb-27,
.py-27 {
  padding-bottom: 13.5rem !important; }

.ml-27,
.mx-27 {
  margin-left: 13.5rem !important; }

.mr-27,
.mx-27 {
  margin-right: 13.5rem !important; }

.mt-27,
.my-27 {
  margin-top: 13.5rem !important; }

.mb-27,
.my-27 {
  margin-bottom: 13.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-27,
  .px-xl-27 {
    padding-left: 13.5rem !important; }
  .pr-xl-27,
  .px-xl-27 {
    padding-right: 13.5rem !important; }
  .pt-xl-27,
  .py-xl-27 {
    padding-top: 13.5rem !important; }
  .pb-xl-27,
  .py-xl-27 {
    padding-bottom: 13.5rem !important; }
  .ml-xl-27,
  .mx-xl-27 {
    margin-left: 13.5rem !important; }
  .mr-xl-27,
  .mx-xl-27 {
    margin-right: 13.5rem !important; }
  .mt-xl-27,
  .my-xl-27 {
    margin-top: 13.5rem !important; }
  .mb-xl-27,
  .my-xl-27 {
    margin-bottom: 13.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-27,
  .px-lg-27 {
    padding-left: 13.5rem !important; }
  .pr-lg-27,
  .px-lg-27 {
    padding-right: 13.5rem !important; }
  .pt-lg-27,
  .py-lg-27 {
    padding-top: 13.5rem !important; }
  .pb-lg-27,
  .py-lg-27 {
    padding-bottom: 13.5rem !important; }
  .ml-lg-27,
  .mx-lg-27 {
    margin-left: 13.5rem !important; }
  .mr-lg-27,
  .mx-lg-27 {
    margin-right: 13.5rem !important; }
  .mt-lg-27,
  .my-lg-27 {
    margin-top: 13.5rem !important; }
  .mb-lg-27,
  .my-lg-27 {
    margin-bottom: 13.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-27,
  .px-md-27 {
    padding-left: 13.5rem !important; }
  .pr-md-27,
  .px-md-27 {
    padding-right: 13.5rem !important; }
  .pt-md-27,
  .py-md-27 {
    padding-top: 13.5rem !important; }
  .pb-md-27,
  .py-md-27 {
    padding-bottom: 13.5rem !important; }
  .ml-md-27,
  .mx-md-27 {
    margin-left: 13.5rem !important; }
  .mr-md-27,
  .mx-md-27 {
    margin-right: 13.5rem !important; }
  .mt-md-27,
  .my-md-27 {
    margin-top: 13.5rem !important; }
  .mb-md-27,
  .my-md-27 {
    margin-bottom: 13.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-27,
  .px-sm-27 {
    padding-left: 13.5rem !important; }
  .pr-sm-27,
  .px-sm-27 {
    padding-right: 13.5rem !important; }
  .pt-sm-27,
  .py-sm-27 {
    padding-top: 13.5rem !important; }
  .pb-sm-27,
  .py-sm-27 {
    padding-bottom: 13.5rem !important; }
  .ml-sm-27,
  .mx-sm-27 {
    margin-left: 13.5rem !important; }
  .mr-sm-27,
  .mx-sm-27 {
    margin-right: 13.5rem !important; }
  .mt-sm-27,
  .my-sm-27 {
    margin-top: 13.5rem !important; }
  .mb-sm-27,
  .my-sm-27 {
    margin-bottom: 13.5rem !important; } }

.pl-28,
.px-28 {
  padding-left: 14rem !important; }

.pr-28,
.px-28 {
  padding-right: 14rem !important; }

.pt-28,
.py-28 {
  padding-top: 14rem !important; }

.pb-28,
.py-28 {
  padding-bottom: 14rem !important; }

.ml-28,
.mx-28 {
  margin-left: 14rem !important; }

.mr-28,
.mx-28 {
  margin-right: 14rem !important; }

.mt-28,
.my-28 {
  margin-top: 14rem !important; }

.mb-28,
.my-28 {
  margin-bottom: 14rem !important; }

@media (min-width: 1200px) {
  .pl-xl-28,
  .px-xl-28 {
    padding-left: 14rem !important; }
  .pr-xl-28,
  .px-xl-28 {
    padding-right: 14rem !important; }
  .pt-xl-28,
  .py-xl-28 {
    padding-top: 14rem !important; }
  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 14rem !important; }
  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 14rem !important; }
  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 14rem !important; }
  .mt-xl-28,
  .my-xl-28 {
    margin-top: 14rem !important; }
  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 14rem !important; } }

@media (min-width: 992px) {
  .pl-lg-28,
  .px-lg-28 {
    padding-left: 14rem !important; }
  .pr-lg-28,
  .px-lg-28 {
    padding-right: 14rem !important; }
  .pt-lg-28,
  .py-lg-28 {
    padding-top: 14rem !important; }
  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 14rem !important; }
  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 14rem !important; }
  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 14rem !important; }
  .mt-lg-28,
  .my-lg-28 {
    margin-top: 14rem !important; }
  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 14rem !important; } }

@media (min-width: 768px) {
  .pl-md-28,
  .px-md-28 {
    padding-left: 14rem !important; }
  .pr-md-28,
  .px-md-28 {
    padding-right: 14rem !important; }
  .pt-md-28,
  .py-md-28 {
    padding-top: 14rem !important; }
  .pb-md-28,
  .py-md-28 {
    padding-bottom: 14rem !important; }
  .ml-md-28,
  .mx-md-28 {
    margin-left: 14rem !important; }
  .mr-md-28,
  .mx-md-28 {
    margin-right: 14rem !important; }
  .mt-md-28,
  .my-md-28 {
    margin-top: 14rem !important; }
  .mb-md-28,
  .my-md-28 {
    margin-bottom: 14rem !important; } }

@media (min-width: 576px) {
  .pl-sm-28,
  .px-sm-28 {
    padding-left: 14rem !important; }
  .pr-sm-28,
  .px-sm-28 {
    padding-right: 14rem !important; }
  .pt-sm-28,
  .py-sm-28 {
    padding-top: 14rem !important; }
  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 14rem !important; }
  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 14rem !important; }
  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 14rem !important; }
  .mt-sm-28,
  .my-sm-28 {
    margin-top: 14rem !important; }
  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 14rem !important; } }

.pl-29,
.px-29 {
  padding-left: 14.5rem !important; }

.pr-29,
.px-29 {
  padding-right: 14.5rem !important; }

.pt-29,
.py-29 {
  padding-top: 14.5rem !important; }

.pb-29,
.py-29 {
  padding-bottom: 14.5rem !important; }

.ml-29,
.mx-29 {
  margin-left: 14.5rem !important; }

.mr-29,
.mx-29 {
  margin-right: 14.5rem !important; }

.mt-29,
.my-29 {
  margin-top: 14.5rem !important; }

.mb-29,
.my-29 {
  margin-bottom: 14.5rem !important; }

@media (min-width: 1200px) {
  .pl-xl-29,
  .px-xl-29 {
    padding-left: 14.5rem !important; }
  .pr-xl-29,
  .px-xl-29 {
    padding-right: 14.5rem !important; }
  .pt-xl-29,
  .py-xl-29 {
    padding-top: 14.5rem !important; }
  .pb-xl-29,
  .py-xl-29 {
    padding-bottom: 14.5rem !important; }
  .ml-xl-29,
  .mx-xl-29 {
    margin-left: 14.5rem !important; }
  .mr-xl-29,
  .mx-xl-29 {
    margin-right: 14.5rem !important; }
  .mt-xl-29,
  .my-xl-29 {
    margin-top: 14.5rem !important; }
  .mb-xl-29,
  .my-xl-29 {
    margin-bottom: 14.5rem !important; } }

@media (min-width: 992px) {
  .pl-lg-29,
  .px-lg-29 {
    padding-left: 14.5rem !important; }
  .pr-lg-29,
  .px-lg-29 {
    padding-right: 14.5rem !important; }
  .pt-lg-29,
  .py-lg-29 {
    padding-top: 14.5rem !important; }
  .pb-lg-29,
  .py-lg-29 {
    padding-bottom: 14.5rem !important; }
  .ml-lg-29,
  .mx-lg-29 {
    margin-left: 14.5rem !important; }
  .mr-lg-29,
  .mx-lg-29 {
    margin-right: 14.5rem !important; }
  .mt-lg-29,
  .my-lg-29 {
    margin-top: 14.5rem !important; }
  .mb-lg-29,
  .my-lg-29 {
    margin-bottom: 14.5rem !important; } }

@media (min-width: 768px) {
  .pl-md-29,
  .px-md-29 {
    padding-left: 14.5rem !important; }
  .pr-md-29,
  .px-md-29 {
    padding-right: 14.5rem !important; }
  .pt-md-29,
  .py-md-29 {
    padding-top: 14.5rem !important; }
  .pb-md-29,
  .py-md-29 {
    padding-bottom: 14.5rem !important; }
  .ml-md-29,
  .mx-md-29 {
    margin-left: 14.5rem !important; }
  .mr-md-29,
  .mx-md-29 {
    margin-right: 14.5rem !important; }
  .mt-md-29,
  .my-md-29 {
    margin-top: 14.5rem !important; }
  .mb-md-29,
  .my-md-29 {
    margin-bottom: 14.5rem !important; } }

@media (min-width: 576px) {
  .pl-sm-29,
  .px-sm-29 {
    padding-left: 14.5rem !important; }
  .pr-sm-29,
  .px-sm-29 {
    padding-right: 14.5rem !important; }
  .pt-sm-29,
  .py-sm-29 {
    padding-top: 14.5rem !important; }
  .pb-sm-29,
  .py-sm-29 {
    padding-bottom: 14.5rem !important; }
  .ml-sm-29,
  .mx-sm-29 {
    margin-left: 14.5rem !important; }
  .mr-sm-29,
  .mx-sm-29 {
    margin-right: 14.5rem !important; }
  .mt-sm-29,
  .my-sm-29 {
    margin-top: 14.5rem !important; }
  .mb-sm-29,
  .my-sm-29 {
    margin-bottom: 14.5rem !important; } }

.pl-30,
.px-30 {
  padding-left: 15rem !important; }

.pr-30,
.px-30 {
  padding-right: 15rem !important; }

.pt-30,
.py-30 {
  padding-top: 15rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 15rem !important; }

.ml-30,
.mx-30 {
  margin-left: 15rem !important; }

.mr-30,
.mx-30 {
  margin-right: 15rem !important; }

.mt-30,
.my-30 {
  margin-top: 15rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 15rem !important; }

@media (min-width: 1200px) {
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 15rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 15rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 15rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 15rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 15rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 15rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 15rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 15rem !important; } }

@media (min-width: 992px) {
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 15rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 15rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 15rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 15rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 15rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 15rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 15rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 15rem !important; } }

@media (min-width: 768px) {
  .pl-md-30,
  .px-md-30 {
    padding-left: 15rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 15rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 15rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 15rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 15rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 15rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 15rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 15rem !important; } }

@media (min-width: 576px) {
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 15rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 15rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 15rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 15rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 15rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 15rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 15rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 15rem !important; } }

.header {
  background: #005c89;
  height: 10rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  z-index: 10; }
  .header__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around; }
  .header__logo {
    margin-top: 1rem;
    transition: 0.4s; }
    .header__logo img {
      width: 22rem;
      height: 8rem;
      transition: 0.4s; }
  .header nav {
    display: none;
    position: absolute;
    top: 8rem;
    left: 0;
    width: 100%;
    background: #005c89;
    padding: 0 1.5rem;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1); }
    @media (min-width: 768px) {
      .header nav {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        padding: 0;
        display: flex;
        align-items: center;
        box-shadow: none; } }
    .header nav ul {
      display: flex;
      flex-direction: column; }
      @media (min-width: 768px) {
        .header nav ul {
          height: 100%;
          flex-direction: row;
          align-items: center; } }
      .header nav ul li {
        height: 100%;
        margin-right: 3rem; }
        .header nav ul li:last-child {
          margin-right: 0; }
        .header nav ul li a {
          position: relative;
          font-size: 2.4rem;
          color: #ffffff;
          height: 100%;
          display: flex;
          align-items: center; }
          @media (min-width: 768px) {
            .header nav ul li a::after {
              content: "";
              border-bottom: 5px solid #ffc000;
              width: 0%;
              position: absolute;
              left: 0;
              bottom: 1rem;
              transition: 0.3s; }
            .header nav ul li a:hover::after {
              width: 100%; } }
        @media (min-width: 992px) {
          .header nav ul li:hover div {
            display: flex; } }
  .header.sticky {
    height: 8rem;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1); }
    .header.sticky .header__logo {
      margin-top: 0.5rem; }
      .header.sticky .header__logo img {
        width: 17rem;
        height: 7rem; }

.hamburger {
  display: block;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .hamburger {
      display: none;
      margin-right: 0; } }
  .hamburger__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 35px;
    height: 30px; }
  .hamburger__bars {
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: #ffffff;
    transition: transform 220ms ease-in-out; }
    .hamburger__bars:before, .hamburger__bars:after {
      display: block;
      position: absolute;
      width: 35px;
      height: 2px;
      background-color: #ffffff;
      content: ""; }
    .hamburger__bars:before {
      top: -12px;
      transition: top 100ms 250ms ease-in, transform 220ms ease-in-out; }
    .hamburger__bars:after {
      bottom: -12px;
      transition: bottom 100ms 250ms ease-in, transform 220ms ease-in-out; }

.hamburger--active .hamburger__bars {
  transform: rotate(225deg);
  transition: transform 220ms 120ms ease-in-out; }
  .hamburger--active .hamburger__bars:before {
    top: 0;
    transition: top 100ms ease-out; }
  .hamburger--active .hamburger__bars:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 100ms ease-out, transform 220ms 120ms ease-in-out; }

.hamburger:focus,
.hamburger__container:focus {
  outline: none; }

.hero {
  padding-top: 8rem; }
  .hero__item {
    position: relative;
    background: #005c89;
    height: auto;
    display: flex;
    align-items: flex-end;
    padding: 24rem 0 2rem 0;
    width: 100%;
    z-index: 1; }
    @media (min-width: 768px) {
      .hero__item {
        padding: 0;
        height: 58rem;
        align-items: center; } }
    @media (min-width: 1200px) {
      .hero__item {
        align-items: flex-end;
        height: 78rem; } }
    @media (min-width: 1200px) and (min-height: 580px) {
      .hero__item {
        height: calc(100vh - 18rem); } }
    @media (min-width: 1200px) and (min-height: 700px) {
      .hero__item {
        padding-bottom: 5rem;
        height: calc(100vh - 18rem); } }
    @media (min-width: 1200px) and (min-height: 800px) {
      .hero__item {
        padding-bottom: 10rem;
        height: calc(100vh - 18rem); } }
  .hero__wrapper {
    min-height: 35rem; }
    .hero__wrapper h2 {
      font-size: 3.5rem;
      margin: 0 0 3rem 0;
      color: #ffffff; }
      @media (min-width: 768px) {
        .hero__wrapper h2 {
          font-size: 5.5rem; } }
      @media (min-width: 1200px) {
        .hero__wrapper h2 {
          font-size: 7.5rem; } }
    .hero__wrapper p {
      font-size: 1.4rem;
      margin: 0 0 4rem 0;
      font-family: "Aller";
      color: #ffffff; }
      @media (min-width: 768px) {
        .hero__wrapper p {
          font-size: 1.6rem; } }
      @media (min-width: 1200px) {
        .hero__wrapper p {
          font-size: 1.8rem; } }
      .hero__wrapper p span {
        font-family: "Aller-Bold"; }
  .hero__actions a:first-child {
    height: 5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #001837;
    background: #ffc000;
    border: 2px solid #ffc000;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .hero__actions a:first-child {
        padding: 0 3.5rem;
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .hero__actions a:first-child {
        padding: 0 4.5rem;
        font-size: 2.5rem; } }
    .hero__actions a:first-child:hover {
      color: #ffc000;
      background: #001837; }
  .hero__actions a:last-child {
    height: 5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;
    background: #001837;
    border: 2px solid #001837;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .hero__actions a:last-child {
        padding: 0 3.5rem;
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .hero__actions a:last-child {
        padding: 0 4.5rem;
        font-size: 2.5rem; } }
    .hero__actions a:last-child:hover {
      color: #001837;
      background: #ffffff; }
  .hero__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @supports not (-ms-high-contrast: none) {
      .hero__img {
        transition-delay: 1.4s;
        transition-duration: 2s;
        transform: scale3d(1, 1, 1);
        overflow: hidden; } }
  .hero__slider {
    position: absolute;
    top: 0;
    right: 0;
    height: 22rem;
    width: 100%; }
    @media (min-width: 768px) {
      .hero__slider {
        height: 100%;
        width: 50%; } }
    .hero__slider .owl-stage-outer,
    .hero__slider .owl-stage,
    .hero__slider .owl-item {
      height: 100%;
      overflow: hidden; }
    @supports not (-ms-high-contrast: none) {
      .hero__slider .owl-item.active .hero__img {
        transform: scale3d(1.1, 1.1, 1.1); } }
    .hero__slider .owl-dots {
      position: absolute;
      top: 19rem; }
      @media (min-width: 768px) {
        .hero__slider .owl-dots {
          top: auto;
          bottom: 2rem;
          left: 2rem;
          transform: none; } }
      .hero__slider .owl-dots .owl-dot {
        width: 2.4rem;
        height: 2.4rem;
        border: 4px solid #ffffff;
        margin: 0 0.5rem;
        transition: 0.3s;
        background: #ffffff; }
        @media (min-width: 768px) {
          .hero__slider .owl-dots .owl-dot {
            width: 3.4rem;
            height: 3.4rem;
            border: 6px solid #ffffff; } }
        .hero__slider .owl-dots .owl-dot.active {
          background: transparent; }

.hp {
  background: #e9e9e9; }
  .hp__main {
    position: relative;
    padding-bottom: 6rem; }
    @media (min-width: 576px) {
      .hp__main {
        padding-bottom: 10rem; } }
    @media (min-width: 768px) {
      .hp__main {
        padding-bottom: 20rem; } }
    .hp__main::after {
      content: "";
      display: block;
      width: calc((100% - 111rem) / 2 + (111rem * 2 / 3));
      height: 100%;
      background: #005c89;
      position: absolute;
      top: 0;
      right: 0; }
      @media (min-width: 768px) {
        .hp__main::after {
          width: calc((100% - 69rem) / 2 + (69rem * 2 / 3)); } }
      @media (min-width: 992px) {
        .hp__main::after {
          width: calc((100% - 93rem) / 2 + (93rem * 2 / 3)); } }
      @media (min-width: 1200px) {
        .hp__main::after {
          width: calc((100% - 111rem) / 2 + (111rem * 2 / 3)); } }
  .hp__accent {
    position: relative; }
    .hp__accent::before {
      content: "";
      display: block;
      width: 18rem;
      height: 5rem;
      background: #ffc000;
      position: absolute;
      left: -18rem;
      top: -5rem; }
  .hp__img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 60vw; }
    @media (min-width: 576px) {
      .hp__img {
        height: 28rem; } }
    @media (min-width: 768px) {
      .hp__img {
        height: 18rem; } }
    @media (min-width: 992px) {
      .hp__img {
        height: 24rem; } }
    @media (min-width: 1200px) {
      .hp__img {
        height: 28rem; } }
  .hp__bg {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: 0.4s; }
  .hp__layer {
    background: #005c89;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s; }
  .hp__content {
    height: 100%;
    background: #ffffff;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .hp__content {
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .hp__content {
        padding: 5.5rem 3rem; }
        .hp__content::after {
          content: "";
          display: block;
          border-right: 1px solid #e9e9e9;
          height: 80%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%); } }
    @media (min-width: 1200px) {
      .hp__content {
        padding: 5.5rem; } }
    .hp__content h3 {
      font-size: 3.5rem;
      font-weight: 500;
      color: #022263;
      text-transform: uppercase;
      margin: 0 0 3.5rem 0; }
    .hp__content p {
      font-size: 1.6rem;
      color: #353535;
      margin: 0 0 6rem 0;
      font-family: "Aller"; }
    .hp__content a {
      height: 5rem;
      display: inline-flex;
      align-items: center;
      padding: 0 2.5rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #ffffff;
      background: #001837;
      border: 2px solid #001837;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .hp__content a {
          padding: 0 3.5rem;
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .hp__content a {
          padding: 0 4.5rem;
          font-size: 2.5rem; } }
      .hp__content a:hover {
        color: #001837;
        background: #ffffff; }
      .hp__content a:hover {
        background: #ffc000;
        border-color: #ffc000; }
  .hp__box {
    position: relative;
    z-index: 1; }
    @media (min-width: 576px) {
      .hp__box {
        height: calc(100% - 28rem); } }
    @media (min-width: 768px) {
      .hp__box {
        height: calc(100% - 18rem); } }
    @media (min-width: 992px) {
      .hp__box {
        height: calc(100% - 24rem); } }
    @media (min-width: 1200px) {
      .hp__box {
        height: calc(100% - 28rem); } }
    .hp__box:hover .hp__bg {
      transform: scale3d(1.1, 1.1, 1.1); }
    .hp__box:hover .hp__layer {
      opacity: 0.6; }

.info-box {
  padding: 4rem 6.5rem 6.5rem 6.5rem;
  min-height: 29.5rem; }
  @media (min-width: 768px) {
    .info-box {
      padding: 3rem; } }
  @media (min-width: 992px) {
    .info-box {
      padding: 4rem 4.5rem 4.5rem 4.5rem; } }
  @media (min-width: 1200px) {
    .info-box {
      padding: 4rem 6.5rem 6.5rem 6.5rem; } }
  .info-box__top {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .info-box__top span {
      font-weight: 500;
      line-height: 0.5;
      display: flex;
      align-items: flex-end;
      height: 11.5rem;
      font-size: 4.5rem; }
      @media (min-width: 992px) {
        .info-box__top span {
          font-size: 5.5rem; } }
      @media (min-width: 1200px) {
        .info-box__top span {
          font-size: 7.5rem; } }
  .info-box__bottom p {
    font-size: 1.6rem;
    font-family: "Aller"; }
  .info-box--blue {
    background: #005c89; }
    .info-box--blue .info-box__top span {
      color: #ffffff; }
    .info-box--blue .info-box__bottom p {
      color: #ffffff; }
  .info-box--yellow {
    background: #ffc000; }
    .info-box--yellow .info-box__top span {
      color: #022263; }
    .info-box--yellow .info-box__bottom p {
      color: #022263; }
  .info-box__img {
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vw; }
    @media (min-width: 576px) {
      .info-box__img {
        height: 20rem; } }
    @media (min-width: 768px) {
      .info-box__img {
        height: 100%; } }

.info-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem; }
  @media (min-width: 768px) {
    .info-btn {
      height: 100%; } }
  .info-btn a {
    height: 5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;
    background: #001837;
    border: 2px solid #001837;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .info-btn a {
        padding: 0 3.5rem;
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .info-btn a {
        padding: 0 4.5rem;
        font-size: 2.5rem; } }
    .info-btn a:hover {
      color: #001837;
      background: #ffffff; }

@media (min-width: 1200px) {
  .hp-realizations {
    margin-bottom: 6rem; } }

.hp-realizations__accent {
  position: relative; }
  @media (min-width: 1200px) {
    .hp-realizations__accent::after {
      content: "";
      display: block;
      border-bottom: 1px solid #3f3f3f;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(50% + 7.75rem); } }

.hp-realizations__more {
  display: flex;
  justify-content: center;
  padding: 6rem 0; }
  .hp-realizations__more a {
    height: 5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #ffffff;
    background: #001837;
    border: 2px solid #001837;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .hp-realizations__more a {
        padding: 0 3.5rem;
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .hp-realizations__more a {
        padding: 0 4.5rem;
        font-size: 2.5rem; } }
    .hp-realizations__more a:hover {
      color: #001837;
      background: #ffffff; }

.hp-realization__img {
  overflow: hidden;
  height: 28rem;
  width: 100%; }
  @media (min-width: 992px) {
    .hp-realization__img {
      height: 22rem; } }
  @media (min-width: 1200px) {
    .hp-realization__img {
      height: 28rem; } }

.hp-realization__bg {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  transition: 0.4s; }

.hp-realization__layer {
  background: #005c89;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s; }

.hp-realization:hover .hp-realization__bg {
  transform: scale3d(1.1, 1.1, 1.1); }

.hp-realization:hover .hp-realization__layer {
  opacity: 0.6; }

.footer {
  background: #005c89;
  padding: 10rem 0 5rem 0;
  color: #ffffff; }
  .footer__leftside h4 {
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem; }
    .footer__leftside h4 span {
      color: #ffc000; }
  .footer__leftside address {
    font-size: 1.6rem;
    margin-bottom: 6rem;
    font-family: "Aller"; }
  .footer__leftside ul {
    margin: 0;
    padding: 0;
    display: flex; }
    .footer__leftside ul li {
      margin-right: 1rem; }
      @media (min-width: 576px) {
        .footer__leftside ul li {
          margin-right: 2.5rem; } }
      .footer__leftside ul li:last-child {
        margin-right: 0; }
      .footer__leftside ul li a {
        color: #ffffff;
        font-size: 1.4rem;
        font-family: "Aller"; }
        @media (min-width: 576px) {
          .footer__leftside ul li a {
            font-size: 1.6rem; } }
        .footer__leftside ul li a:hover {
          color: #ffc000; }
  .footer__phone {
    font-family: "Teko", sans-serif;
    font-size: 3rem;
    color: #ffffff;
    margin-right: 2rem;
    display: block; }
    @media (min-width: 576px) {
      .footer__phone {
        font-size: 4.5rem; } }
    @media (min-width: 992px) {
      .footer__phone {
        display: inline-block;
        font-size: 7.5rem; } }
    .footer__phone:hover {
      color: #ffc000; }
  .footer__email {
    font-family: "Teko", sans-serif;
    font-size: 2.4rem;
    color: #ffffff; }
    @media (min-width: 576px) {
      .footer__email {
        font-size: 3.6rem; } }
    .footer__email:hover {
      color: #ffc000; }
  .footer__rightside h4 {
    font-size: 3.6rem;
    font-weight: 400;
    margin: 4rem 0; }
    @media (min-width: 768px) {
      .footer__rightside h4 {
        margin: 0 0 4rem 0; } }
    .footer__rightside h4 span {
      color: #ffc000;
      display: block; }
  .footer__rightside p {
    margin-bottom: 3rem;
    color: #ffffff; }

.trust {
  background: #e9e9e9;
  position: relative;
  height: 100%; }
  @media (min-width: 576px) {
    .trust {
      height: 10rem; } }
  .trust .container,
  .trust .row {
    height: 100%; }
  .trust::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #005c89;
    position: absolute;
    top: 0;
    left: 0; }
    @media (min-width: 576px) {
      .trust::before {
        width: 50%; } }
  .trust::after {
    content: "";
    display: block;
    position: absolute;
    background: #e9e9e9;
    left: 0;
    bottom: 0;
    height: calc(100% - 12rem);
    width: 100%; }
    @media (min-width: 576px) {
      .trust::after {
        display: none; } }
    @media (min-width: 992px) {
      .trust::after {
        display: block;
        width: 10rem;
        height: 10rem;
        background: #ffc000;
        top: 0;
        right: 50%;
        left: auto; } }
  .trust__header {
    position: relative;
    height: 12rem;
    background: #001837;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 576px) {
      .trust__header {
        position: absolute;
        top: 0;
        right: 50%;
        width: 10rem;
        height: 10rem; } }
    @media (min-width: 992px) {
      .trust__header {
        right: auto;
        left: 50%; } }
    .trust__header h3 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: 500; }
  .trust__slider {
    height: 10rem; }
    @media (min-width: 576px) {
      .trust__slider {
        position: absolute;
        top: 0;
        right: 2rem;
        width: calc(50% - 4rem); } }
    @media (min-width: 992px) {
      .trust__slider {
        width: calc(50% - 14rem); } }
    .trust__slider .owl-stage-outer,
    .trust__slider .owl-stage,
    .trust__slider .owl-item {
      height: 100%; }
    .trust__slider.owl-carousel img {
      max-width: 100%;
      max-height: 10rem;
      width: auto;
      height: auto;
      opacity: 0.4; }
  .trust__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }

.title {
  font-size: 4rem;
  color: #022263;
  font-weight: 400;
  padding: 5rem 0; }
  @media (min-width: 576px) {
    .title {
      font-size: 5rem;
      padding: 7rem 0; } }
  @media (min-width: 768px) {
    .title {
      padding: 9rem 0; } }
  .title span {
    color: #ffc000; }

.about-us {
  margin: 0 0 4rem 0;
  position: relative; }
  @media (min-width: 768px) {
    .about-us {
      margin: 3rem 0 8rem 0;
      padding-top: 5rem;
      overflow: hidden; } }
  .about-us::before {
    content: "";
    display: none; }
    @media (min-width: 768px) {
      .about-us::before {
        display: block;
        max-width: 18rem;
        width: 100%;
        height: 5rem;
        background: #ffc000;
        position: absolute;
        top: 0;
        left: calc((100% - 69rem) / 2 + 69rem); } }
    @media (min-width: 992px) {
      .about-us::before {
        left: calc((100% - 93rem) / 2 + 93rem); } }
    @media (min-width: 1200px) {
      .about-us::before {
        left: calc((100% - 111rem) / 2 + 111rem); } }
  .about-us__accent {
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 54vw; }
    @media (min-width: 768px) {
      .about-us__accent {
        position: absolute;
        top: 5rem;
        left: 0;
        width: calc((100% - 111rem) / 2 + 111rem * 2 / 3);
        height: 59rem; } }
    @media (min-width: 1200px) {
      .about-us__accent {
        height: 60.6rem; } }

.text {
  padding: 0 0 4rem 0; }
  @media (min-width: 768px) {
    .text {
      padding: 0 0 8rem 0; } }
  .text p {
    font-family: "Aller";
    font-size: 1.4rem;
    color: #353535;
    margin-bottom: 2rem; }
    @media (min-width: 576px) {
      .text p {
        font-size: 1.6rem; } }
  .text a {
    color: #353535;
    text-decoration: underline; }
    .text a:hover {
      color: #ffc000; }
  .text ul {
    margin: 0 0 2rem 0;
    padding: 0 0 0 3rem; }
    .text ul li {
      font-family: "Aller";
      font-size: 1.4rem;
      color: #353535; }
      @media (min-width: 768px) {
        .text ul li {
          font-size: 1.6rem; } }
      .text ul li::before {
        content: "\25A0";
        font-size: 2rem;
        display: inline-block;
        width: 3rem;
        margin-left: -3rem;
        color: #ffc000; }
        @media (min-width: 768px) {
          .text ul li::before {
            font-size: 2.6rem; } }

.people__btns {
  display: flex;
  justify-content: flex-end; }

.people__btn {
  width: 14.5rem;
  height: 14.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .people__btn img {
    margin-bottom: 1rem; }
  .people__btn span {
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: uppercase; }
  .people__btn--blue {
    background: #001837;
    color: #ffffff; }
  .people__btn--yellow {
    background: #ffc000;
    color: #001837; }

.people__slider {
  margin: 6rem 0;
  position: relative; }
  .people__slider::before {
    content: "";
    display: block;
    width: 18rem;
    height: 5rem;
    background: #ffc000;
    position: absolute;
    left: -18rem;
    top: -5rem; }

.people__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem; }
  .people__box img {
    margin-bottom: 5rem; }
  .people__box h4 {
    font-size: 3.5rem;
    color: #022263;
    margin-bottom: 1rem; }
  .people__box p {
    font-family: "Aller";
    font-size: 1.6rem;
    color: #353535; }

.join-us {
  position: relative;
  margin-bottom: 14rem; }
  @media (min-width: 768px) {
    .join-us {
      margin-bottom: 24rem; } }
  .join-us::after {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% + 6rem);
    background: #005c89;
    position: absolute;
    top: 0;
    right: 0; }
    @media (min-width: 768px) {
      .join-us::after {
        height: calc(100% + 15rem);
        width: calc((100% - 69rem) / 2 + (69rem * 2 / 3)); } }
    @media (min-width: 992px) {
      .join-us::after {
        width: calc((100% - 93rem) / 2 + (93rem * 2 / 3)); } }
    @media (min-width: 1200px) {
      .join-us::after {
        width: calc((100% - 111rem) / 2 + (111rem * 2 / 3)); } }
  .join-us__wrapper {
    position: relative;
    background: #ffffff;
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1; }
    @media (min-width: 576px) {
      .join-us__wrapper {
        padding: 3rem 2rem; } }
    @media (min-width: 992px) {
      .join-us__wrapper {
        padding: 5rem; } }
  .join-us .title {
    padding: 3rem 0; }
  .join-us .text {
    padding: 0; }

.banner {
  position: relative;
  margin-top: 8rem;
  height: 23rem;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center; }
  .banner::before {
    content: "";
    display: block;
    width: 18rem;
    height: 5rem;
    background: #ffc000;
    position: absolute;
    left: 0;
    bottom: 0; }
  .banner__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 1200px) {
      .banner__wrapper {
        flex-direction: row;
        align-items: center;
        justify-content: space-between; } }
    .banner__wrapper h2 {
      color: #ffffff;
      font-size: 2.5rem; }
      @media (min-width: 576px) {
        .banner__wrapper h2 {
          font-size: 3.4rem; } }
      @media (min-width: 992px) {
        .banner__wrapper h2 {
          font-size: 4.4rem; } }
      @media (min-width: 1200px) {
        .banner__wrapper h2 {
          font-size: 5.2rem; } }
    .banner__wrapper p {
      font-family: "Aller";
      font-size: 1.4rem;
      color: #ffffff;
      margin-bottom: 1rem; }
      @media (min-width: 576px) {
        .banner__wrapper p {
          font-size: 1.6rem; } }
    .banner__wrapper ul {
      margin: 0;
      padding: 0;
      display: flex; }
      .banner__wrapper ul li {
        font-size: 1.3rem;
        font-weight: 500;
        text-transform: uppercase;
        display: flex; }
        @media (min-width: 576px) {
          .banner__wrapper ul li {
            font-size: 1.8rem; } }
        .banner__wrapper ul li::after {
          content: "-";
          color: #ffffff;
          display: block;
          margin: 0 0.3rem; }
          @media (min-width: 576px) {
            .banner__wrapper ul li::after {
              margin: 0 1rem; } }
        .banner__wrapper ul li a {
          color: #ffffff; }
        .banner__wrapper ul li:last-child::after {
          display: none; }

.offer {
  position: relative;
  margin-bottom: 8rem; }
  .offer__accent {
    position: relative;
    height: 66vw; }
    @media (min-width: 768px) {
      .offer__accent {
        position: absolute;
        top: 5rem;
        width: 50%; }
        .offer__accent::before {
          content: "";
          display: block;
          width: 18rem;
          height: 5rem;
          background: #ffc000;
          position: absolute; } }
    .offer__accent::after {
      content: "";
      display: block;
      width: calc(100% - 10rem);
      height: 100%;
      background: #005c89;
      position: absolute;
      top: 0; }
      @media (min-width: 768px) {
        .offer__accent::after {
          width: calc(100% - 16rem); } }
  .offer__img {
    position: relative;
    height: calc(100% - 4rem);
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    @media (min-width: 576px) {
      .offer__img {
        height: calc(100% - 8rem); } }
    @media (min-width: 768px) {
      .offer__img {
        height: calc(100% - 16rem); } }
  .offer__info {
    margin-top: 4rem; }
    @media (min-width: 576px) {
      .offer__info {
        margin-top: 6rem; } }
    @media (min-width: 768px) {
      .offer__info {
        margin-top: 12rem; } }
    .offer__info h4 {
      font-size: 5rem;
      font-weight: 400;
      text-transform: uppercase;
      color: #022263;
      margin-bottom: 4rem; }
      @media (min-width: 1200px) {
        .offer__info h4 {
          margin-bottom: 6rem; } }
    .offer__info p.offer-title {
      font-size: 3.5rem;
      font-weight: 500;
      color: #022263;
      margin-bottom: 1.5rem;
      font-family: "Teko", sans-serif; }
    .offer__info ul {
      margin: 0 0 2rem 0;
      padding: 0 0 4rem 3rem; }
      .offer__info ul li {
        font-family: "Aller";
        font-size: 1.4rem;
        color: #353535; }
        @media (min-width: 768px) {
          .offer__info ul li {
            font-size: 1.6rem; } }
        .offer__info ul li::before {
          content: "\25A0";
          font-size: 2rem;
          display: inline-block;
          width: 3rem;
          margin-left: -3rem;
          color: #ffc000; }
          @media (min-width: 768px) {
            .offer__info ul li::before {
              font-size: 2.6rem; } }
    .offer__info a {
      height: 5rem;
      display: inline-flex;
      align-items: center;
      padding: 0 2.5rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #ffffff;
      background: #022263;
      border: 2px solid #022263;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .offer__info a {
          padding: 0 3.5rem;
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .offer__info a {
          padding: 0 4.5rem;
          font-size: 2.5rem; } }
      .offer__info a:hover {
        color: #022263;
        background: #ffffff; }
  .offer__text {
    padding: 0; }
  .offer--left .offer__accent {
    left: 0; }
    .offer--left .offer__accent::before {
      right: -18rem;
      top: -5rem; }
    .offer--left .offer__accent::after {
      left: 0; }
  .offer--right .offer__accent {
    right: 0; }
    .offer--right .offer__accent::before {
      left: -18rem;
      top: -5rem; }
    .offer--right .offer__accent::after {
      right: 0; }

.opinions {
  position: relative;
  margin-bottom: 10rem; }
  @media (min-width: 576px) {
    .opinions {
      margin-bottom: 20rem; } }
  @media (min-width: 992px) {
    .opinions {
      margin-bottom: 30rem; } }
  @media (min-width: 576px) {
    .opinions::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: calc(100% + 10rem);
      background: #005c89; } }
  @media (min-width: 992px) {
    .opinions::before {
      height: calc(100% + 14rem); } }
  .opinions__wrapper {
    padding: 2rem;
    background: #ffffff; }
    @media (min-width: 576px) {
      .opinions__wrapper {
        padding: 3rem 4rem 2rem 4rem; } }
    @media (min-width: 768px) {
      .opinions__wrapper {
        padding: 4rem 6rem 2rem 6rem; } }
    @media (min-width: 992px) {
      .opinions__wrapper {
        padding: 6rem 10rem 2rem 10rem; } }
    @media (min-width: 576px) {
      .opinions__wrapper::before {
        content: "";
        position: absolute;
        bottom: -10rem;
        left: 1.5rem;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10rem 6rem 0 0;
        border-color: #ffffff transparent transparent transparent; } }
    @media (min-width: 992px) {
      .opinions__wrapper::before {
        bottom: -14rem;
        border-width: 14rem 8rem 0 0; } }
    .opinions__wrapper h4 {
      font-size: 4rem;
      font-weight: 400; }
      @media (min-width: 576px) {
        .opinions__wrapper h4 {
          font-size: 5rem; } }
      .opinions__wrapper h4.blue {
        color: #022263; }
      .opinions__wrapper h4.yellow {
        color: #ffc000; }
  .opinions__slider {
    margin-top: 4rem; }
    .opinions__slider p {
      font-family: "Teko", sans-serif;
      font-size: 1.6rem;
      color: #022263; }
      @media (min-width: 576px) {
        .opinions__slider p {
          font-size: 2rem; } }
      @media (min-width: 768px) {
        .opinions__slider p {
          font-size: 2.6rem; } }
      @media (min-width: 992px) {
        .opinions__slider p {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .opinions__slider p {
          font-size: 3.5rem; } }
    .opinions__slider.owl-carousel .owl-item img {
      max-width: 100%;
      width: auto; }
  .opinions__btns {
    background: #ffffff;
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .opinions__btns {
        margin-top: -10rem; } }
  .opinions__btn {
    position: relative;
    width: 14.5rem;
    height: 14.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1; }
    .opinions__btn img {
      margin-bottom: 1rem; }
    .opinions__btn span {
      font-size: 2.5rem;
      font-weight: 500;
      text-transform: uppercase; }
    .opinions__btn--blue {
      background: #001837;
      color: #ffffff; }
    .opinions__btn--yellow {
      background: #ffc000;
      color: #001837; }

.realizations__filters {
  margin: 8rem 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .realizations__filters li {
    margin: 0.8rem; }
    .realizations__filters li a {
      height: 5rem;
      display: inline-flex;
      align-items: center;
      padding: 0 1.5rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #ffffff;
      background: #001837;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .realizations__filters li a {
          font-size: 2rem;
          padding: 0 2.5rem; } }
      @media (min-width: 992px) {
        .realizations__filters li a {
          font-size: 2.5rem; } }
      .realizations__filters li a:hover, .realizations__filters li a.active {
        background: #ffc000;
        color: #001837; }

.realizations__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.realizations__paggination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0; }
  .realizations__paggination li {
    margin: 0 1rem; }
    .realizations__paggination li a {
      font-size: 1.8rem;
      font-weight: 500;
      color: #001837;
      font-family: "Aller";
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent; }
      .realizations__paggination li a.active, .realizations__paggination li a:hover {
        color: #ffc000;
        border-top: 1px solid #ffc000;
        border-bottom: 1px solid #ffc000; }
    .realizations__paggination li:first-child a, .realizations__paggination li:last-child a {
      font-size: 2.5rem;
      font-family: "Teko", sans-serif;
      text-transform: uppercase; }

.realiztion {
  width: 100%;
  height: 100vw;
  position: relative;
  cursor: pointer; }
  @media (min-width: 440px) {
    .realiztion {
      width: 50%;
      height: 50vw; } }
  @media (min-width: 768px) {
    .realiztion {
      width: 33.3333%;
      height: 33.3333vw; } }
  @media (min-width: 992px) {
    .realiztion {
      width: 25%;
      height: 25vw; } }
  .realiztion__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .realiztion__layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #005c89;
    opacity: 0;
    transition: 0.3s; }
  .realiztion__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem; }
    @media (min-width: 576px) {
      .realiztion__text {
        padding: 2rem; } }
    @media (min-width: 768px) {
      .realiztion__text {
        padding: 3rem; } }
    @media (min-width: 992px) {
      .realiztion__text {
        padding: 4rem; } }
    @media (min-width: 1200px) {
      .realiztion__text {
        padding: 5rem; } }
  .realiztion__name {
    position: relative;
    font-size: 3.5rem;
    font-weight: 500;
    color: #ffffff;
    z-index: 1; }
  .realiztion__city {
    font-family: "Aller";
    font-size: 1.6rem;
    color: #ffffff; }
  .realiztion:hover .realiztion__layer {
    opacity: 0.6; }
  .realiztion:hover .realiztion__text {
    opacity: 1; }

.logos {
  margin: 8rem 0; }
  .logos__slider {
    opacity: 0.4; }
    .logos__slider.owl-carousel {
      height: 100%; }
      .logos__slider.owl-carousel .owl-stage-outer,
      .logos__slider.owl-carousel .owl-stage {
        height: 100%; }
      .logos__slider.owl-carousel .owl-item {
        height: 100%; }
        .logos__slider.owl-carousel .owl-item img {
          max-width: 100%;
          width: auto;
          display: block; }
  .logos__item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.single-realization__back {
  height: 5rem;
  display: inline-flex;
  align-items: center;
  padding: 0 2.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
  background: #022263;
  border: 2px solid #022263;
  text-transform: uppercase;
  margin: 3rem 0; }
  @media (min-width: 768px) {
    .single-realization__back {
      padding: 0 3.5rem;
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .single-realization__back {
      padding: 0 4.5rem;
      font-size: 2.5rem; } }
  .single-realization__back:hover {
    color: #022263;
    background: #ffffff; }

.single-realization__top {
  width: 100%;
  height: 67rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.single-realization__slider {
  margin: 8rem 0; }
  .single-realization__slider.owl-carousel .owl-item img {
    width: auto; }

.single-realization__item img {
  max-height: 26rem; }
  @media (min-width: 576px) {
    .single-realization__item img {
      max-height: 32rem; } }
  @media (min-width: 768px) {
    .single-realization__item img {
      max-height: 46rem; } }
  @media (min-width: 992px) {
    .single-realization__item img {
      max-height: 56rem; } }

.single-realization__btns {
  position: relative;
  margin-top: -14.5rem;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-bottom: 8rem; }

.single-realization__btn {
  width: 14.5rem;
  height: 14.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .single-realization__btn img {
    margin-bottom: 1rem; }
  .single-realization__btn span {
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: uppercase; }
  .single-realization__btn--blue {
    background: #001837;
    color: #ffffff; }
    .single-realization__btn--blue:hover {
      color: #ffffff; }
  .single-realization__btn--yellow {
    background: #ffc000;
    color: #001837; }
    .single-realization__btn--yellow:hover {
      color: #001837; }

.single-realization__info h3 {
  font-size: 4rem;
  font-weight: 400;
  color: #022263;
  margin-bottom: 3.5rem; }
  @media (min-width: 768px) {
    .single-realization__info h3 {
      font-size: 5rem; } }

.single-realization__info h4 {
  font-size: 3.5rem;
  font-weight: 500;
  color: #022263;
  margin-bottom: 1.5rem; }

.single-realization__text {
  margin-bottom: 8rem; }

.accordion {
  margin: 4rem 0; }
  .accordion__item {
    margin-bottom: 1.5rem; }
  .accordion__header {
    border-bottom: 1px solid #e9e9e9;
    background: #ffffff; }
  .accordion__arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 5rem;
    background: #ffc000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s; }
    @media (min-width: 992px) {
      .accordion__arrow {
        width: 13rem;
        height: 13rem; } }
    .accordion__arrow svg {
      fill: #001837;
      transform: rotate(-180deg);
      transition: 0.4s; }
  .accordion__btn {
    position: relative;
    width: 100%;
    min-height: 8rem;
    text-align: left;
    background: #ffffff;
    transition: 0.4s;
    padding: 0 2rem 0 7rem; }
    @media (min-width: 992px) {
      .accordion__btn {
        padding: 0 4rem 0 17rem;
        height: 13rem; } }
    .accordion__btn.collapsed .accordion__arrow {
      background: #001837; }
      .accordion__btn.collapsed .accordion__arrow svg {
        fill: #ffffff;
        transform: rotate(0); }
  .accordion__title {
    display: block;
    font-size: 2rem;
    font-weight: 500;
    color: #022263; }
    @media (min-width: 992px) {
      .accordion__title {
        font-size: 3.5rem;
        margin-bottom: 0.6rem; } }
  .accordion__subtitle {
    display: block;
    font-family: "Aller";
    font-size: 1.4rem;
    color: #353535; }
    @media (min-width: 992px) {
      .accordion__subtitle {
        font-size: 1.6rem; } }
  .accordion__content {
    background: #ffffff;
    padding: 2rem 1.5rem; }
    @media (min-width: 576px) {
      .accordion__content {
        padding: 2rem 1.5rem 2rem 7rem; } }
    @media (min-width: 992px) {
      .accordion__content {
        padding: 4rem 4rem 4rem 17rem; } }
    .accordion__content h4 {
      font-size: 2.85rem;
      font-weight: 500;
      color: #022263;
      margin-bottom: 2.5rem; }
      @media (min-width: 768px) {
        .accordion__content h4 {
          font-size: 3.5rem;
          margin-bottom: 3.5rem; } }
    .accordion__content ul {
      margin: 0 0 3rem 0;
      padding: 0 0 0 3rem; }
      .accordion__content ul li {
        font-family: "Aller";
        font-size: 1.4rem;
        color: #353535; }
        @media (min-width: 768px) {
          .accordion__content ul li {
            font-size: 1.6rem; } }
        .accordion__content ul li::before {
          content: "\25A0";
          font-size: 2rem;
          display: inline-block;
          width: 3rem;
          margin-left: -3rem;
          color: #ffc000; }
          @media (min-width: 768px) {
            .accordion__content ul li::before {
              font-size: 2.6rem; } }
    .accordion__content p {
      font-family: "Aller";
      font-size: 1.4rem;
      color: #353535;
      margin-bottom: 2rem; }
      @media (min-width: 768px) {
        .accordion__content p {
          font-size: 1.6rem; } }
    .accordion__content a {
      font-size: 2rem;
      color: #ffc000;
      font-family: "Teko", sans-serif; }
      @media (min-width: 576px) {
        .accordion__content a {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .accordion__content a {
          font-size: 3rem; } }
      @media (min-width: 992px) {
        .accordion__content a {
          font-size: 3.5rem; } }
      .accordion__content a:hover {
        color: #022263; }

.job__accent {
  position: relative; }
  .job__accent::before {
    content: "";
    display: block;
    width: 18rem;
    height: 5rem;
    background: #ffc000;
    position: absolute;
    left: -18rem;
    top: -5rem; }

.map iframe {
  width: 100%;
  height: 52rem;
  border: none;
  margin-bottom: -3rem; }

.contact {
  margin: 6rem 0; }
  @media (min-width: 768px) {
    .contact {
      margin: 12rem 0 6rem 0; } }
  .contact__wrapper {
    margin: 0 0 6rem 0; }
    @media (min-width: 768px) {
      .contact__wrapper {
        margin: 4rem 0 0 0; } }
    .contact__wrapper h4 {
      font-size: 3.5rem;
      font-weight: 400;
      color: #022263; }
      @media (min-width: 768px) {
        .contact__wrapper h4 {
          font-size: 5rem; } }
    .contact__wrapper p {
      font-family: "Aller";
      font-size: 1.4rem;
      color: #353535;
      margin-bottom: 2rem; }
      @media (min-width: 576px) {
        .contact__wrapper p {
          font-size: 1.6rem; } }
    .contact__wrapper a {
      font-family: "Teko", sans-serif;
      font-size: 3.5rem;
      color: #ffc000; }
      @media (min-width: 768px) {
        .contact__wrapper a {
          font-size: 5.5rem; } }
      .contact__wrapper a:hover {
        color: #022263; }
  .contact form {
    position: relative; }
    @media (min-width: 768px) {
      .contact form::before {
        content: "";
        display: block;
        width: 18rem;
        height: 5rem;
        background: #ffc000;
        position: absolute;
        left: -18rem;
        top: -5rem; } }
    .contact form input,
    .contact form textarea {
      padding-left: 2rem;
      width: 100%;
      border: none;
      font-family: "Aller";
      font-size: 1.4rem;
      color: #353535;
      margin-bottom: 1rem; }
      @media (min-width: 576px) {
        .contact form input,
        .contact form textarea {
          font-size: 1.6rem; } }
      .contact form input::-webkit-input-placeholder,
      .contact form textarea::-webkit-input-placeholder {
        font-size: 1.4rem;
        color: #353535; }
      .contact form input::-moz-placeholder,
      .contact form textarea::-moz-placeholder {
        font-size: 1.4rem;
        color: #353535; }
      .contact form input:-ms-input-placeholder,
      .contact form textarea:-ms-input-placeholder {
        font-size: 1.4rem;
        color: #353535; }
      .contact form input:-moz-placeholder,
      .contact form textarea:-moz-placeholder {
        font-size: 1.4rem;
        color: #353535; }
    .contact form input {
      height: 5rem; }
      @media (min-width: 992px) {
        .contact form input {
          height: 8rem; } }
      .contact form input[type="submit"] {
        margin-top: 2rem;
        padding: 0;
        font-family: "Teko", sans-serif;
        height: 5rem;
        width: 22rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 500;
        color: #ffffff;
        background: #022263;
        border: 2px solid #022263;
        text-transform: uppercase;
        transition: 0.3s; }
        @media (min-width: 768px) {
          .contact form input[type="submit"] {
            font-size: 2rem; } }
        @media (min-width: 1200px) {
          .contact form input[type="submit"] {
            font-size: 2.5rem; } }
        .contact form input[type="submit"]:hover {
          color: #022263;
          background: #ffffff; }
    .contact form textarea {
      height: 14rem;
      padding: 2rem; }
      @media (min-width: 992px) {
        .contact form textarea {
          height: 22rem; } }

.submenu {
  display: none; }
  @media (min-width: 992px) {
    .submenu {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 21px 14.88px 1.12px rgba(0, 0, 0, 0.19);
      top: 8rem;
      right: 0;
      width: 94rem; } }
  @media (min-width: 1200px) {
    .submenu {
      width: 110rem; } }
  .submenu a {
    display: flex;
    flex-direction: column;
    width: 33.3333%;
    border: none;
    padding: 2rem;
    align-items: flex-start !important; }
    @media (min-width: 1200px) {
      .submenu a {
        padding: 4rem; } }
    .submenu a::after {
      display: none; }
    .submenu a div {
      width: 100%;
      height: 12rem;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 2rem; }
      @media (min-width: 1200px) {
        .submenu a div {
          height: 14rem; } }
    .submenu a span {
      font-size: 2.4rem;
      font-weight: 500;
      color: #022263; }
      @media (min-width: 1200px) {
        .submenu a span {
          font-size: 2.6rem; } }
